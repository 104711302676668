import { stat } from 'fs';
import React, { useEffect, useState } from 'react';
import './App.css';

interface ApiResponse {
  instanceId?: string;
  status?: string;
  message?: string;
}

function App() {

  const [idToken, setIdToken] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  const [expiresIn, setExpiresIn] = useState<string>();
  const [tokenType, setTokenType] = useState<string>();
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instanceId] = useState('i-008a284463908f7cd')
  const [status, setStatus] = useState('unknown')
  const [message, setMessage] = useState<string | undefined>()

  // On first load only - check the URI HASH as this is where AWS stuffs the return token. 
  // TODO - Remove the Hash once we have this, just leaves the place untitdy otherwise.
  useEffect(() => {
    const parameters = window.location.hash.substr(1).split('&').map((entry) => entry.split('=')).reduce<Record<string, string>>((map, current) => ({ ...map, [current[0]]: current[1] }), {});
    setIdToken(parameters.id_token);
    setAccessToken(parameters.access_token);
    setExpiresIn(parameters.expires_in);
    setTokenType(parameters.token_type);

    // TODO - if we don't have a valid token (token is missing or expired) automatically redirect to login.
  }, []);

  // API helper function, also handles auto-refresh every five seconds if stopping or starting.
  async function api(method: 'GET' | 'POST', uri: string): Promise<void> {
    if (!idToken) {
      setStatus('unknown');
      setMessage('Not authenticated')
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(uri, { method, headers: { 'Authorization': idToken } })
      const json = await response.json() as ApiResponse;
      setStatus(json.status ?? 'unknown');
      setMessage(json.message);
    } catch {
      setStatus('unknown');
      setMessage('Unknown error')
    } finally {
      setLoading(false);
    }
  }

  // If wew are not currently in the middle of an API call and we are currently starting/stopping, ask the server for the status again.
  useEffect(() => {
    if (!loading && (status === 'pending' || status === 'stopping')) {
      setLoading(true);
      setTimeout(() => { api('GET', `https://8wj304o0n2.execute-api.ap-southeast-2.amazonaws.com/prod/server/${instanceId}/status`); }, 5000);
    }
  }, [loading, status])

  // If the ID token changes - set the authorized status accordingly and refresh.
  // TODO - need to check the expiry and validity on the token as well.
  useEffect(() => {
    setAuthorized(idToken !== undefined);
    api('GET', `https://8wj304o0n2.execute-api.ap-southeast-2.amazonaws.com/prod/server/${instanceId}/status`);
  }, [idToken]);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          <button onClick={() => { window.location.href = 'https://auth.itinerantcoder.com/login?client_id=4s3cimrmofrckcvgohbkrd5mq5&response_type=token&scope=openid&redirect_uri=https://www.itinerantcoder.com/' }}>
            Login
          </button>
        </p>
        <p>
          May have to logon even if it says you are authorized, need to update code to check that the session is still valid.
        </p>
        <p>
          Busy: {loading ? 'Loading' : 'Idle'}
        </p>
        <p>
          Authorized: {authorized ? 'Authorized' : 'Not Authorized'}
        </p>
        <p hidden={!authorized}>
          Server Status: {status}
        </p>
        <p>
          <button disabled={status !== 'stopped'} onClick={() => {
            api('POST', `https://8wj304o0n2.execute-api.ap-southeast-2.amazonaws.com/prod/server/${instanceId}/start`);
          }}>Start</button>
        </p>
        <p>
          <button disabled={status !== 'running'} onClick={() => {
            api('POST', `https://8wj304o0n2.execute-api.ap-southeast-2.amazonaws.com/prod/server/${instanceId}/stop`);
          }}>Stop</button>
        </p>
        <p hidden={!authorized || message === undefined}>
          Message from server: {message}
        </p>
      </header>
    </div>
  );
}

export default App;
